import { StyleSheet } from "aphrodite";
import { useCallback, useEffect, useState, VFC } from "react";
import { Button, List } from "./components";
import { Layout } from "~/components/Layout";
import { media, spacing, zIndex } from "~/styles";
import { useBodyFix } from "~/hooks/useBodyFix";

export type NarrowMenuProps = {
  isMedium: boolean;
};

const styles = StyleSheet.create({
  container: {
    position: "fixed",
    top: spacing.xsmall,
    right: spacing.xsmall,
    zIndex: zIndex.onContainer,
    [media.medium]: {
      display: "none"
    }
  }
});

export const NarrowMenu: VFC<NarrowMenuProps> = ({ isMedium }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const activateHandler = useCallback(() => setIsActive((state) => !state), []);

  const closeHanlder = () => {
    setIsActive(false);
  };

  const { toFix, toFlow } = useBodyFix();
  useEffect(() => {
    if (isActive) {
      toFix();
    } else {
      toFlow();
    }
  }, [isActive]);
  return (
    <Layout element="div" aria-hidden={isMedium} styles={styles.container}>
      <Button isActive={isActive} activateHandler={activateHandler} />
      <List isActive={isActive} closeHandler={closeHanlder} />
    </Layout>
  );
};
