export const colors = {
  white: "#fff",
  black: "#22272e",
  almostBlack: "#2d343d",
  gray: "#777471",
  stone: "#edeae6",
  beige: "#f9f8f4",
  stoneTint: "#f6f4f2",
  yellow: "#f5d652",
  canary: "#fff5ca",
  canaryTint: "#fffae5",
  cobalt: "#0074e4",
  sky: "#b6dbff",
  skyTint: "#daedfe",
  skyLight: "#eef8ff",
  blurple: "#5522fa",
  lavender: "#d5d2ff",
  lavenderTint: "#eae8fe",
  purple: "#7700a2",
  pink: "#cb84de",
  pinkTint: "#efdaf5",
  salmon: "#ec8580",
  peach: "#fee5da",
  peachTint: "#fef2ed",
  teal: "#5ceace",
  mint: "#b3f8eb",
  mintTint: "#d8f9f0",
  red: "#c50000",
  shadow: "rgba(0,0,0,0.13)",
  shadowDark: "rgba(0,0,0,0.21)"
} as const;
