import { StyleSheet } from "aphrodite";
import { FC, ReactNode } from "react";
import { Layout } from "~/components/Layout";
import { colors, maxWidth, media, shadow, spacing } from "~/styles";

export type TransformedProps = {
  backgrondColor?: string;
  maxWidth?: string;
  children?: ReactNode;
};

export const Transformed: FC<TransformedProps> = ({
  backgrondColor: background = colors.white,
  maxWidth: width = maxWidth.xlarge,
  children
}) => {
  const styles = StyleSheet.create({
    container: {
      width: "100%",
      maxWidth: width,
      marginTop: "-8rem",
      marginBottom: "0",
      marginLeft: "auto",
      marginRight: "auto",
      background: background,
      boxShadow: shadow.xxlarge,
      paddingTop: spacing.small,
      paddingBottom: spacing.small,
      paddingLeft: spacing.medium,
      paddingRight: spacing.medium,
      [media.medium]: {
        paddingTop: spacing.large,
        paddingBottom: spacing.large,
        paddingLeft: spacing.xlarge,
        paddingRight: spacing.xlarge,
        marginLeft: `-${spacing.large}`,
        marginRight: `-${spacing.large}`
      }
    }
  });
  return (
    <Layout element="div" styles={styles.container}>
      {children}
    </Layout>
  );
};
