import { css, StyleSheet } from "aphrodite";
import { ReactNode, FC } from "react";

export type ItemProps = {
  children?: ReactNode;
  styles?: object;
};

const styles = StyleSheet.create({
  item: {
    display: "block"
  }
});

export const Item: FC<ItemProps> = ({ children, styles: itemStyles }) => {
  return <li className={css(styles.item, itemStyles)}>{children}</li>;
};
