import { css, StyleSheet } from "aphrodite";
import { ReactNode, FC } from "react";
import { Section } from "./components";
import { fontFamily, fontSizes, spacing } from "~/styles";

export type TextProps = {
  element?: "p" | "span" | "div";
  children?: ReactNode;
  styles?: object;
};

const styles = StyleSheet.create({
  text: {
    display: "block",
    textAlign: "left",
    fontSize: fontSizes.text.medium,
    fontWeight: 400,
    fontFamily: fontFamily.sans,
    whiteSpace: "pre-wrap",
    marginTop: spacing.medium,
    marginBottom: spacing.medium,
    ":first-child": {
      marginTop: "0"
    }
  }
});

export const Text: FC<TextProps> & {
  Section: typeof Section;
} = ({ element: Element = "p", children, styles: textStyles }) => {
  return <Element className={css(styles.text, textStyles)}>{children}</Element>;
};

Text.Section = Section;
