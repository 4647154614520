import { CSSProperties, FC } from "react";

export type OptionProps = {
  value: string;
  disabled?: boolean;
  defaultChecked?: boolean;
  style?: CSSProperties;
  children: string;
};

export const Option: FC<OptionProps> = ({
  value,
  disabled,
  defaultChecked,
  style,
  children
}) => {
  return (
    <option
      value={value}
      disabled={disabled}
      defaultChecked={defaultChecked}
      style={style}
    >
      {children}
    </option>
  );
};
