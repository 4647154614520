import { StyleSheet } from "aphrodite";
import { ReactNode, VFC } from "react";
import { Layout } from "../Layout";
import { media, spacing, variables } from "~/styles";

export type PageMainLayoutProps = {
  renderComponent?: ReactNode;
};

const styles = StyleSheet.create({
  main: {
    paddingBottom: spacing.xlarge,
    minHeight: "100vh",
    width: "100%",
    [media.iOS]: {
      minHeight: variables.fullScreenHeight
    }
  }
});

export const PageMainLayout: VFC<PageMainLayoutProps> = ({
  renderComponent
}) => {
  return (
    <Layout element="main" id="content" styles={styles.main}>
      {renderComponent}
    </Layout>
  );
};
