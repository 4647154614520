import { StyleSheet } from "aphrodite";
import { VFC } from "react";
import dayjs from "dayjs";
import { Layout } from "../Layout";
import { Text } from "../Text";
import { InlineText } from "../InlineText";
import { Image, Navigation } from "./components";
import {
  colors,
  fontFamily,
  fontSizes,
  maxWidth,
  media,
  spacing,
  variables
} from "~/styles";
import { useNavigation } from "~/hooks/useNavigation";

const styles = StyleSheet.create({
  footer: {
    background: colors.stone,
    paddingTop: spacing.xlarge,
    paddingBottom: spacing.xxlarge,
    paddingLeft: spacing.small,
    paddingRight: spacing.small,
    [media.medium]: {
      paddingLeft: variables.sidebarSpacing
    }
  },
  inner: {
    maxWidth: maxWidth.xlarge,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%"
  },
  topContainer: {
    paddingBottom: spacing.medium,
    [media.small]: {
      flexDirection: "row"
    }
  },
  image: {
    marginBottom: spacing.medium,
    [media.small]: {
      marginBottom: "0"
    }
  },
  navigation: {
    flex: "1 1 auto",
    [media.small]: {
      flexDirection: "row",
      justifyContent: "flex-end"
    }
  },
  copyright: {
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: colors.shadowDark,
    paddingTop: spacing.small
  },
  copyrightText: {
    fontSize: fontSizes.text.xsmall,
    fontFamily: fontFamily.serif,
    fontWeight: 400,
    color: colors.gray,
    marginTop: "0",
    marginBottom: spacing.medium
  }
});

export const PageFooter: VFC = () => {
  const { navigation, onlineShop, koudoShop } = useNavigation();
  const copyrightText = `Copyright © 2007-${dayjs().format(
    "YYYY"
  )} yoneichi, Co.,Ltd. All Rights Reserved.`;
  return (
    <Layout element="footer" styles={styles.footer}>
      <Layout element="div" styles={styles.inner}>
        <Layout element="div" styles={styles.topContainer}>
          <Layout element="div" styles={styles.image}>
            <Image />
          </Layout>
          <Layout element="div" styles={styles.navigation}>
            <Navigation
              items={navigation.map((v) => ({
                name: v.label,
                label: v.label,
                path: v.path
              }))}
            />
            <Navigation
              items={[
                {
                  name: onlineShop.label,
                  path: onlineShop.path,
                  label: onlineShop.label,
                  external: true
                },
                {
                  name: koudoShop.label,
                  path: koudoShop.path,
                  label: koudoShop.label,
                  external: true
                }
              ]}
            />
          </Layout>
        </Layout>
        <Layout element="div" styles={styles.copyright}>
          <Text styles={styles.copyrightText}>
            <InlineText element="em">{copyrightText}</InlineText>
          </Text>
        </Layout>
      </Layout>
    </Layout>
  );
};
