import { css, StyleSheet } from "aphrodite";
import { forwardRef, ReactNode, AriaAttributes } from "react";
import { colors, fontSizes } from "~/styles";

export type LayoutProps = {
  id?: string;
  element?:
    | "div"
    | "header"
    | "footer"
    | "article"
    | "main"
    | "aside"
    | "nav"
    | "section";
  styles?: object;
  children?: ReactNode;
};

const styles = StyleSheet.create({
  layout: {
    position: "relative",
    paddingTop: "0",
    paddingLeft: "0",
    paddingRight: "0",
    paddingBottom: "0",
    marginTop: "0",
    marginLeft: "0",
    marginRight: "0",
    marginBottom: "0",
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
    flexBasis: "auto",
    alignItems: "stretch",
    borderWidth: "0",
    borderStyle: "solid",
    borderColor: "transparent",
    boxSizing: "border-box",
    minWidth: "0px",
    minHeight: "0px",
    color: colors.black,
    fontSize: fontSizes.text.medium
  }
});

export const Layout = forwardRef<any, LayoutProps & AriaAttributes>(
  (
    { children, styles: layoutStyles, element: Element = "div", ...props },
    ref
  ) => {
    return (
      <Element
        ref={ref}
        className={css(styles.layout, layoutStyles)}
        {...props}
      >
        {children}
      </Element>
    );
  }
);
