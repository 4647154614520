import { StyleSheet } from "aphrodite";
import { VFC } from "react";
import { InlineText } from "~/components/InlineText";
import { Link } from "~/components/Link";
import { NavigationList } from "~/components/NavigationList";
import { fontSizes, media, spacing } from "~/styles";

export type NavigationProps = {
  items: {
    name: string;
    label: string;
    path: string;
    external?: boolean;
  }[];
};

const styles = StyleSheet.create({
  navigation: {
    marginBottom: spacing.medium,
    ":last-child": {
      marginBottom: "0"
    },
    [media.small]: {
      marginBottom: "0",
      marginLeft: spacing.medium
    }
  },
  link: {
    fontSize: fontSizes.text.small,
    fontWeight: 400,
    ":hover": {
      textDecoration: "underline"
    },
    ":focus": {
      textDecoration: "underline"
    }
  }
});

export const Navigation: VFC<NavigationProps> = ({ items }) => {
  return (
    <NavigationList styles={styles.navigation}>
      {items.map((item, i) => (
        <NavigationList.Item key={`${item.name}[${i}]`}>
          <Link
            href={item.path}
            label={item.label}
            external={item.external}
            styles={styles.link}
          >
            <InlineText element="span">{item.name}</InlineText>
          </Link>
        </NavigationList.Item>
      ))}
    </NavigationList>
  );
};
