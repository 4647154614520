import { useRouter } from "next/dist/client/router";
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState
} from "react";
import { Dictionary, ja, cn, Lang, LangAttributes } from "~/lib/client/dic";

type State = {
  lang: Lang;
  dic: Dictionary;
  setLangProperty: (lang: Lang) => void;
};

type Props = {
  children: ReactNode;
};

const TranslationContext = createContext<State>({
  lang: "ja",
  dic: ja,
  setLangProperty: () => {}
});

export const TranslationProvider: FC<Props> = ({ children }) => {
  const { locale } = useRouter();
  const t: Lang = locale === "zh-cmn-Hans" ? "cn" : "ja";

  const [lang, setLang] = useState<Lang>(t);
  const [dic, setDic] = useState<Dictionary>(t === "cn" ? cn : ja);
  const setLangProperty = (lang: Lang) => {
    setLang(lang);
  };
  const setLangAttributes = (lang: LangAttributes) => {
    document.documentElement.setAttribute("lang", lang);
  };

  const bodyClassName = {
    cn: "lang--cn",
    ja: "lang--ja"
  };
  const setStyleAttributes = (isCn: boolean) => {
    if (isCn) {
      document.body.classList.add(bodyClassName.cn);
      document.body.classList.remove(bodyClassName.ja);
    } else {
      document.body.classList.add(bodyClassName.ja);
      document.body.classList.remove(bodyClassName.cn);
    }
  };

  useEffect(() => {
    switch (lang) {
      case "ja":
        setDic(ja);
        setLangAttributes("ja");
        setStyleAttributes(false);
        break;
      case "cn":
        setDic(cn);
        setLangAttributes("zh-cmn-Hans");
        setStyleAttributes(true);
        break;
      default:
        throw new Error();
    }
  }, [lang, setDic]);

  return (
    <TranslationContext.Provider value={{ lang, dic, setLangProperty }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => useContext(TranslationContext);
