import { css, StyleSheet, CSSProperties } from "aphrodite";
import { ChangeEventHandler, ReactNode, FC } from "react";
import { Layout } from "../Layout";
import { Option } from "./components";
import { borderRadius, colors, leading, spacing } from "~/styles";

export type OptionItem = {
  value: string;
  defaultChecked?: boolean;
};

export type ActionSelectProps = {
  onChange(value: string): void;
  renderBeforeElement?: ReactNode;
  renderAfterElement?: ReactNode;
  children: ReactNode;
  containerStyles?: CSSProperties;
  selectStyles?: CSSProperties;
};

export const ActionSelect: FC<ActionSelectProps> & {
  Option: typeof Option;
} = ({
  onChange,
  renderBeforeElement,
  renderAfterElement,
  children,
  containerStyles = {},
  selectStyles = {}
}) => {
  const handleChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const { value } = e.currentTarget;
    onChange(value);
  };
  const styles = StyleSheet.create({
    container: {
      display: "inline-flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: "auto",
      maxWidth: "100%",
      position: "relative",
      background: "#fff",
      borderRadius: borderRadius.medium,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: colors.stone,
      ...containerStyles
    },
    select: {
      margin: "0",
      appearance: "none",
      cursor: "pointer",
      width: "100%",
      display: "block",
      paddingTop: spacing.xsmall,
      paddingBottom: spacing.xsmall,
      paddingLeft: spacing.medium,
      paddingRight: spacing.medium,
      border: "0",
      outline: "none",
      background: "transparent",
      fontSize: "16px",
      lineHeight: leading.none,
      ...selectStyles
    }
  });
  return (
    <Layout styles={styles.container}>
      {renderBeforeElement}
      <select onChange={handleChange} className={css(styles.select)}>
        {children}
      </select>
      {renderAfterElement}
    </Layout>
  );
};

ActionSelect.Option = Option;
