import { faHome, faStore } from "@fortawesome/free-solid-svg-icons";
import { StyleSheet } from "aphrodite";
import { useMemo, VFC } from "react";
import { Icon } from "~/components/Icon";
import { Layout } from "~/components/Layout";
import { Link } from "~/components/Link";
import { NavigationList } from "~/components/NavigationList";
import { Text } from "~/components/Text";
import { useNavigation } from "~/hooks/useNavigation";
import {
  colors,
  fontFamily,
  fontSizes,
  leading,
  shadow,
  spacing
} from "~/styles";

export type ListProps = {
  isActive: boolean;
  closeHandler: () => void;
};

const styles = StyleSheet.create({
  nav: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "fixed",
    top: "0px",
    right: "0px",
    width: "100%",
    maxWidth: "41rem",
    height: "100%",
    paddingTop: "6rem",
    backgroundColor: colors.stoneTint,
    boxShadow: shadow.xlarge,
    overflowY: "auto",
    transition: "transform 350ms ease",
    transform: "translateX(calc(100% + 4rem))"
  },
  navIsActive: {
    transform: "translateX(0px)"
  },
  list: {
    paddingTop: spacing.medium,
    paddingBottom: spacing.medium,
    paddingLeft: spacing.large,
    paddingRight: spacing.medium,
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: colors.shadowDark,
    margin: "0px",
    display: "block",
    flex: "1 1 auto"
  },
  item: {
    marginBottom: spacing.medium
  },
  link: {
    display: "inline-block",
    color: colors.black,
    transition: "transform 200ms ease",
    ":hover": {
      color: colors.black,
      textDecoration: "underline"
    },
    ":active": {
      transform: "scale(0.96)"
    }
  },
  linkName: {
    display: "block",
    fontFamily: fontFamily.serif,
    fontWeight: 700,
    fontSize: fontSizes.title.medium,
    margin: "0"
  },
  linkLabel: {
    display: "block",
    fontFamily: fontFamily.sans,
    fontWeight: 400,
    fontSize: fontSizes.text.small,
    margin: "0"
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexShrink: 1,
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: colors.shadowDark,
    paddingTop: spacing.xsmall,
    paddingBottom: spacing.xsmall,
    paddingLeft: spacing.large,
    paddingRight: spacing.medium
  },
  footerLink: {
    display: "inline-flex",
    alignItems: "center",
    lineHeight: leading.none,
    fontSize: fontSizes.text.small,
    fontWeight: 700,
    color: colors.gray,
    textDecoration: "none",
    paddingTop: spacing.xsmall,
    paddingBottom: spacing.xsmall
  },
  footerLinkIcon: {
    display: "block",
    width: "1rem",
    height: "1rem",
    marginRight: spacing.xxsmall
  }
});

export const List: VFC<ListProps> = ({ isActive, closeHandler }) => {
  const { navigation, home, onlineShop } = useNavigation();
  const navStyles = useMemo(() => {
    return StyleSheet.create({
      nav: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "fixed",
        top: "0px",
        right: "0px",
        width: "100%",
        maxWidth: "41rem",
        height: "100%",
        paddingTop: "6rem",
        backgroundColor: colors.stoneTint,
        boxShadow: shadow.xlarge,
        overflowY: "auto",
        transition: "transform 350ms ease",
        transform: isActive
          ? "translateX(0px)"
          : "translateX(calc(100% + 4rem))"
      }
    }).nav;
  }, [isActive]);
  return (
    <Layout element="nav" aria-labelledby="main-menu" styles={navStyles}>
      <NavigationList styles={styles.list}>
        {navigation.map((item, i) => (
          <NavigationList.Item key={`${item.name}-${i}`} styles={styles.item}>
            <Link
              href={item.path}
              label={item.label}
              styles={styles.link}
              onClick={closeHandler}
            >
              <Text element="span" styles={styles.linkName}>
                {item.name}
              </Text>
              <Text element="span" styles={styles.linkLabel}>
                {item.label}
              </Text>
            </Link>
          </NavigationList.Item>
        ))}
      </NavigationList>
      <Layout element="footer" styles={styles.footer}>
        <Link
          href={home.path}
          label={home.label}
          styles={styles.footerLink}
          onClick={closeHandler}
        >
          <Icon source={faHome} styles={styles.footerLinkIcon} />
          {home.label}
        </Link>
        <Link
          href={onlineShop.path}
          label={onlineShop.label}
          styles={styles.footerLink}
          external
        >
          <Icon source={faStore} styles={styles.footerLinkIcon} />
          {onlineShop.label}
        </Link>
      </Layout>
    </Layout>
  );
};
