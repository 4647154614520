import { StyleSheet } from "aphrodite";
import { VFC } from "react";
import { Layout } from "../Layout";
import { Crumbs, Title } from "./components";
import { colors, media, spacing, variables } from "~/styles";

export type PageHeaderProps = {
  labelText: string;
  headingText: string;
  crumbs: {
    label: string;
    path?: string;
  }[];
};

const styles = StyleSheet.create({
  container: {
    background: colors.stoneTint
  },
  inner: {
    paddingTop: spacing.xxlarge,
    paddingBottom: spacing.xlarge,
    paddingLeft: spacing.small,
    paddingRight: spacing.small,
    [media.medium]: {
      paddingLeft: variables.sidebarSpacing
    }
  }
});

export const PageHeader: VFC<PageHeaderProps> = ({
  labelText,
  headingText,
  crumbs
}) => {
  return (
    <Layout element="div" styles={styles.container}>
      <Layout element="div" styles={styles.inner}>
        <Title labelText={labelText} headingText={headingText} />
        <Crumbs crumbs={crumbs} />
      </Layout>
    </Layout>
  );
};
