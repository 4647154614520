import { CSSProperties, StyleSheet } from "aphrodite";
import { FC, ReactNode } from "react";
import { Layout } from "../Layout";
import { Transformed } from "./components";
import { media, spacing } from "~/styles";

export type SectionProps = {
  withSpacing?: boolean;
  children?: ReactNode;
  styles?: CSSProperties;
};

export const Section: FC<SectionProps> & {
  Transformed: typeof Transformed;
} = ({ withSpacing, children, styles: sectionStyles }) => {
  const styles = StyleSheet.create({
    section: {
      width: "100%",
      maxWidth: "100%",
      paddingLeft: spacing.small,
      paddingRight: spacing.small,
      paddingTop: withSpacing ? "6em" : "0",
      paddingBottom: withSpacing ? "6em" : "0",
      [media.medium]: {
        paddingLeft: "18em"
      },
      ...sectionStyles
    },
    inner: {
      width: "100%",
      maxWidth: media.xlargeWidth,
      margin: "0 auto",
      [media.medium]: {
        paddingLeft: spacing.large,
        paddingRight: spacing.large
      }
    }
  });
  return (
    <Layout element="section" styles={styles.section}>
      <Layout element="div" styles={styles.inner}>
        {children}
      </Layout>
    </Layout>
  );
};

Section.Transformed = Transformed;
