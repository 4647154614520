import { VFC } from "react";
import useMedia from "use-media";
import { Layout } from "../Layout";
import { NarrowMenu, WideMenu } from "./components";
import { media } from "~/styles";

export const PageSidebar: VFC = () => {
  const isMedium = useMedia({
    minWidth: media.mediumWidth
  });
  return (
    <Layout element="header">
      <a href="#content" className="visually-hidden">
        Skip to content
      </a>
      <NarrowMenu isMedium={isMedium} />
      <WideMenu isMedium={isMedium} />
    </Layout>
  );
};
