import { css, StyleSheet } from "aphrodite";
import { ReactNode, FC } from "react";

export type SectionProps = {
  children?: ReactNode;
};

const styles = StyleSheet.create({
  section: {
    display: "inline-block"
  }
});

export const Section: FC<SectionProps> = ({ children }) => {
  return <span className={css(styles.section)}>{children}</span>;
};
