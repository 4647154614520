import { StyleSheet } from "aphrodite";
import { Fragment, VFC } from "react";
import { Heading } from "~/components/Heading";
import { Text } from "~/components/Text";
import { colors, fontFamily, fontSizes, spacing } from "~/styles";

export type TitleProps = {
  labelText: string;
  headingText: string;
};

const styles = StyleSheet.create({
  label: {
    display: "block",
    fontSize: fontSizes.title.large,
    fontWeight: 700,
    fontFamily: fontFamily.serif,
    color: colors.black,
    marginTop: "0",
    marginBottom: spacing.xxsmall
  },
  heading: {
    fontSize: fontSizes.text.xxlarge,
    fontWeight: 400,
    fontFamily: fontFamily.sans,
    margin: "0"
  }
});

export const Title: VFC<TitleProps> = ({ labelText, headingText }) => {
  return (
    <Fragment>
      <Text element="span" styles={styles.label}>
        {labelText}
      </Text>
      <Heading element="h1" styles={styles.heading}>
        {headingText}
      </Heading>
    </Fragment>
  );
};
