import { css, StyleSheet } from "aphrodite";
import { ReactNode, FC } from "react";
import { Item } from "./components";

export type NavigationListProps = {
  children?: ReactNode;
  styles?: object;
  element?: "ul" | "ol";
};

const styles = StyleSheet.create({
  navigationList: {
    margin: "0",
    padding: "0",
    display: "block",
    listStyle: "none"
  }
});

export const NavigationList: FC<NavigationListProps> & {
  Item: typeof Item;
} = ({ children, element: Element = "ul", styles: navigationListStyles }) => {
  return (
    <Element className={css(styles.navigationList, navigationListStyles)}>
      {children}
    </Element>
  );
};

NavigationList.Item = Item;
