import { css } from "aphrodite";
import { ReactNode, FC } from "react";

export type InlineTextStyles = {
  element?: "b" | "em" | "span";
  children?: ReactNode;
  styles?: object;
};

export const InlineText: FC<InlineTextStyles> = ({
  element: Element = "span",
  children,
  styles: inlineTextStyles
}) => {
  return (
    <Element className={inlineTextStyles && css(inlineTextStyles)}>
      {children}
    </Element>
  );
};
