import { StyleSheet } from "aphrodite";
import { VFC } from "react";
import { InlineText } from "~/components/InlineText";
import { Link } from "~/components/Link";
import { NavigationList } from "~/components/NavigationList";
import { colors, fontSizes, spacing } from "~/styles";

export type CrumbsProps = {
  crumbs: {
    label: string;
    path?: string;
  }[];
};

const styles = StyleSheet.create({
  crumbs: {
    display: "block",
    marginLeft: "0px",
    marginTop: spacing.large
  },
  crumbsItem: {
    display: "inline",
    fontSize: fontSizes.text.small,
    ":first-child:before": {
      content: "''",
      margin: "0"
    },
    ":before": {
      content: "'>'",
      margin: "0 0.25rem"
    }
  },
  crumbsLink: {
    display: "inline",
    textDecoration: "none",
    ":hover": {
      textDecoration: "underline"
    }
  },
  crumbsLinkText: {
    color: colors.cobalt
  },
  crumbsText: {
    color: colors.gray
  }
});

export const Crumbs: VFC<CrumbsProps> = ({ crumbs }) => {
  return (
    <NavigationList element="ol" styles={styles.crumbs}>
      {crumbs.map((item, i) =>
        item.path ? (
          <NavigationList.Item
            key={`${item.label}[${i}]`}
            styles={styles.crumbsItem}
          >
            <Link
              href={item.path}
              label={item.label}
              styles={styles.crumbsLink}
            >
              <InlineText element="span" styles={styles.crumbsLinkText}>
                {item.label}
              </InlineText>
            </Link>
          </NavigationList.Item>
        ) : (
          <NavigationList.Item
            key={`${item.label}[${i}]`}
            styles={styles.crumbsItem}
          >
            <InlineText element="span" styles={styles.crumbsText}>
              {item.label}
            </InlineText>
          </NavigationList.Item>
        )
      )}
    </NavigationList>
  );
};
