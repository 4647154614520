import { useRouter } from "next/router";
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useTranslation } from "./useTranslation";

type Props = {
  children: ReactNode;
};

type State = {
  navigation: {
    name: string;
    label: string;
    path: string;
  }[];
  home: {
    name: string;
    label: string;
    path: string;
  };
  onlineShop: {
    name: string;
    label: string;
    path: string;
  };
  koudoShop: {
    label: string;
    path: string;
  };
  onWhite: boolean;
  toggleColor: (state: boolean) => void;
};

const NavigationContext = createContext<State>({
  navigation: [],
  onlineShop: {
    name: "",
    label: "",
    path: ""
  },
  home: {
    name: "",
    label: "",
    path: ""
  },
  koudoShop: {
    label: "",
    path: ""
  },
  onWhite: false,
  toggleColor: () => {}
});

export const NavigationProvider: FC<Props> = ({ children }) => {
  const { pathname } = useRouter();
  const { dic } = useTranslation();
  const navigation = useMemo(() => {
    return [
      {
        name: "About",
        label: dic.navigation.about,
        path: "/about"
      },
      {
        name: "Products",
        label: dic.navigation.products,
        path: "/products"
      },
      {
        name: "News",
        label: dic.navigation.news,
        path: "/news"
      },
      {
        name: "Blog",
        label: dic.navigation.blog,
        path: "/blog"
      },
      {
        name: "Contact",
        label: dic.navigation.contact,
        path: "/contact"
      }
    ];
  }, [dic]);

  const home = useMemo(() => {
    return {
      name: "Home Page",
      label: dic.homepage,
      path: "/"
    };
  }, [dic]);

  const onlineShop = useMemo(() => {
    return {
      name: "Online Shop",
      label: dic.onlineshop,
      path: "https://yoneichi.official.ec/"
    };
  }, [dic]);

  const koudoShop = useMemo(() => {
    return {
      label: dic.koudoShop,
      path: "https://www.yoneichi-koudo.com/"
    };
  }, [dic]);

  const [onWhite, setOnWhite] = useState<boolean>(false);

  const toggleColor = useCallback((state: boolean) => {
    setOnWhite(state);
  }, []);

  useEffect(() => {
    if (pathname === "/") {
      setOnWhite(false);
    } else {
      setOnWhite(true);
    }
  }, [pathname]);
  return (
    <NavigationContext.Provider
      value={{
        navigation,
        home,
        onlineShop,
        koudoShop,
        onWhite,
        toggleColor
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => useContext(NavigationContext);
