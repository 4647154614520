import Head from "next/head";
import { VFC } from "react";
import {
  SITE_DESCRIPTION,
  SITE_OG_IMAGE,
  SITE_TITLE,
  SITE_URL
} from "~/lib/client/values";

export type SEOProps = {
  pageName?: string;
  path?: string;
};

export const SEO: VFC<SEOProps> = ({ pageName, path }) => {
  return (
    <Head>
      <title key="title">
        {pageName ? `${pageName} | ${SITE_TITLE}` : SITE_TITLE}
      </title>
      <meta name="description" key="description" content={SITE_DESCRIPTION} />
      <meta property="og:image" key="og:image" content={SITE_OG_IMAGE} />
      <meta
        property="og:title"
        key="og:title"
        content={pageName ? `${pageName} | ${SITE_TITLE}` : SITE_TITLE}
      />
      <meta
        property="og:description"
        key="og:description"
        content={SITE_DESCRIPTION}
      />
      <meta
        property="og:url"
        key="og:url"
        content={path ? `${SITE_URL}${path}` : SITE_URL}
      />
    </Head>
  );
};
