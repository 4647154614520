import { css, StyleSheet } from "aphrodite";
import { useMemo, VFC } from "react";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Layout } from "../Layout";
import { ActionSelect } from "../ActionSelect";
import { Icon } from "../Icon";
import { useTranslation } from "~/hooks/useTranslation";
import { safearea, spacing, zIndex } from "~/styles";
import { Lang } from "~/lib/client/dic";

export type TranslateControllerProps = {
  fixed?: boolean;
};

export const TranslateController: VFC<TranslateControllerProps> = ({
  fixed
}) => {
  const { setLangProperty, lang } = useTranslation();

  const flagPath = useMemo(() => {
    switch (lang) {
      case "ja": {
        return "/images/flags/jp.png";
      }
      case "cn": {
        return "/images/flags/cn.png";
      }
      default:
        return "";
    }
  }, [lang]);

  function handleChange(value: string) {
    setLangProperty(value as Lang);
  }

  const styles = StyleSheet.create({
    translateController: {
      display: "inline-block",
      position: fixed ? "fixed" : "relative",
      bottom: fixed ? spacing.small : undefined,
      right: fixed ? spacing.small : undefined,
      marginBottom: fixed ? safearea.bottom : undefined,
      zIndex: fixed ? zIndex.foreground : undefined
    },
    flag: {
      display: "block",
      position: "absolute",
      top: `calc(${spacing.xxsmall} + 3px)`,
      left: spacing.xxsmall
    },
    icon: {
      position: "absolute",
      top: spacing.xxsmall,
      right: spacing.xxsmall,
      width: "16px",
      height: "16px"
    }
  });

  return (
    <Layout styles={styles.translateController}>
      <ActionSelect
        selectStyles={{
          paddingTop: spacing.xxsmall,
          paddingBottom: spacing.xxsmall
        }}
        onChange={handleChange}
        renderBeforeElement={
          <img src={flagPath} alt={lang} className={css(styles.flag)} />
        }
        renderAfterElement={<Icon source={faAngleDown} styles={styles.icon} />}
      >
        <ActionSelect.Option value="ja" defaultChecked={lang === "ja"}>
          日本語
        </ActionSelect.Option>
        <ActionSelect.Option value="cn" defaultChecked={lang === "cn"}>
          中文
        </ActionSelect.Option>
      </ActionSelect>
    </Layout>
  );
};
