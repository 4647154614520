export const borderRadius = {
  small: "0.3em",
  medium: "0.5em",
  large: "1em"
} as const;

export const media = {
  xsmall: "@media (min-width: 23.5em)",
  small: "@media (min-width: 46.5em)",
  medium: "@media (min-width: 59.5em)",
  large: "@media (min-width: 70em)",
  xlarge: "@media (min-width: 90em)",
  print: "@media print",
  iOS: "@supports (-webkit-touch-callout:none)",
  xsmallWidth: "23.5em",
  smallWidth: "46.5em",
  mediumWidth: "59.5em",
  largeWidth: "70em",
  xlargeWidth: "90em"
} as const;

export const maxWidth = {
  xsmall: "24.5rem",
  small: "35.5rem",
  medium: "45.5rem",
  large: "67.5rem",
  xlarge: "90rem",
  full: "100%"
} as const;

export const shadow = {
  none: "none",
  small: "0 0.5px 2px rgba(0,0,0,0.21)",
  medium: "0 1px 4px rgba(0,0,0,0.17)",
  large: "0 2px 8px rgba(0,0,0,0.17)",
  xlarge: "0 4px 16px rgba(0,0,0,0.17)",
  xxlarge: "24px 24px 72px rgba(0,0,0,0.21)"
} as const;

export const variables = {
  sidebarSpacing: "18em",
  fullScreenWidth: "calc(var(--vw, 1vw) * 100)",
  fullScreenHeight: "calc(var(--vh, 1vh) * 100)",
  focusRing:
    "rgb(255,255,255) 0px 0px 0px 0.1875rem, rgb(128,128,128) 0px 0px 0px 0.25rem, rgb(255,255,255) 0px 0px 0px 0.3125rem"
} as const;

export const safearea = {
  top: "env(safe-area-inset-top)",
  left: "env(safe-area-inset-left)",
  right: "env(safe-area-inset-right)",
  bottom: "env(safe-area-inset-bottom)"
} as const;

export const zIndex = {
  background: -1000,
  onBackground: -900,
  normal: 0,
  onContainer: 100,
  onPage: 200,
  underForeground: 900,
  foreground: 1000
} as const;
