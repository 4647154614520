export type Lang = "ja" | "cn";

export type LangAttributes = "ja" | "zh-cmn-Hans";

export type ProfileTableTypes = {
  head: string;
  body: string;
};

export type Dictionary = {
  catchphrase: string;
  navigation: {
    about: string;
    products: string;
    news: string;
    blog: string;
    contact: string;
  };
  homepage: string;
  onlineshop: string;
  koudoShop: string;
  descriptions: {
    mottoTop: string;
    mottoBottom: string;
    aboutSummay: string;
    onlineshopSummary: string;
  };
  about: {
    story: string;
    profile: {
      title: string;
      name: ProfileTableTypes;
      ceo: ProfileTableTypes;
      address: ProfileTableTypes;
      capital: ProfileTableTypes;
      establish: ProfileTableTypes;
      directors: ProfileTableTypes;
      customers: ProfileTableTypes;
      salesSection: ProfileTableTypes;
      banks?: ProfileTableTypes;
      offices: ProfileTableTypes;
      affilated: ProfileTableTypes;
      facilities: ProfileTableTypes;
      salesDepartment?: ProfileTableTypes;
      productionDepartment?: ProfileTableTypes;
      products: ProfileTableTypes;
    };
    greeting: {
      title: string;
      body: string;
    };
    images: {
      title: string;
      description: string;
    };
    imageCaptinos: {
      honsya: string;
      cut: string;
      kinzoku: string;
      syokuniku: string;
      mooring: string;
      tochiku: string;
      unloading: string;
      housou: string;
    };
  };
  contact: {
    type: string;
    aboutProduct: string;
    aboutRecruit: string;
    email: string;
    body: string;
    maxString: string;
    send: string;
    koudoInfo: {
      name: string;
      address: string;
      time: string;
      closed: string;
    };
  };
};

export const ja: Dictionary = {
  catchphrase: "よね一の心からの気持ちです。",
  navigation: {
    about: "私たちについて",
    products: "取り扱い商品",
    news: "お知らせ",
    blog: "スタッフブログ",
    contact: "お問い合わせ"
  },
  homepage: "ホーム",
  onlineshop: "オンラインショップ",
  koudoShop: "河渡店",
  descriptions: {
    mottoTop: "厳選された牛肉を安全に皆様へ。",
    mottoBottom: "美味しい中にちょっぴりの贅沢。",
    aboutSummay: `牛肉の卸売会社「よね一」は、村上牛、仙台牛など各種銘柄和牛はじめ国産牛をメインに、牛肉関連商品を幅広く取り扱っております。\n\n`,
    onlineshopSummary: `「よね一」公式オンラインショップをオープンしました。\nより多くの方に『村上牛』をはじめとした「よね一」のお肉を楽しんで頂きたく、オンラインショップをオープンいたしました。\n「よね一」自慢の安心・安全なお肉を是非ご家庭でご堪能ください。`
  },
  about: {
    story:
      "安心、安全は当たり前。美味しい中にちょっぴりの贅沢を。\n「よね一」は、新潟県を代表する「村上牛」、「にいがた和牛」、宮城県を代表する「仙台牛」を中心に各種銘柄牛、国産和牛、国産牛を幅広く取り扱っております。\nまた、国産和牛、国産牛等を原料とした加工品の商品開発・販売を行っております。\n皆様方のお手元に届けるために、レベルの高い衛生や安全性の確保、作業の高度化を可能とした本社工場により徹底した工程での製品化を可能とし、取り巻く環境に対応しております。\nこれからも厳選された牛肉を安全に皆様へお届けすることを通じて、「美味しい中にちょっぴりの贅沢」を皆様方と感じていきたいと思っております。",
    profile: {
      title: "会社概要",
      name: {
        head: "会社名",
        body: "株式会社 よね一"
      },
      ceo: {
        head: "代表取締役",
        body: "八木信一"
      },
      address: {
        head: "住所",
        body: "新潟県新潟市西区的場流通1丁目3番11号"
      },
      capital: {
        head: "資本金",
        body: "1,000万円"
      },
      establish: {
        head: "創立",
        body: "昭和27年6月1日（株式会社に改組）"
      },
      directors: {
        head: "役員",
        body: "代表取締役 八木信一\n常務取締役 八木信彦\n他 取締役2名"
      },
      customers: {
        head: "主要取引先",
        body:
          "全農\n生産農家\n東京食肉市場\n仙台中央食肉卸売市場\n各地方食肉市場"
      },
      salesSection: {
        head: "主要販売先",
        body:
          "各スーパーマーケット\n県内外食品メーカー\n県内外小売専門店\n県内外飲食店関係"
      },
      offices: {
        head: "営業所・グループ会社",
        body: "大阪営業所\n仙台事務所\n直売店（河渡店）\n株式会社みなとや"
      },
      affilated: {
        head: "関連企業",
        body: "新潟ミートパッカー株式会社"
      },
      facilities: {
        head: "施設",
        body: "本社加工工場"
      },
      products: {
        head: "取り扱い商品",
        //eslint-disable-next-line no-irregular-whitespace
        body:
          "新潟県特産「村上牛」　　にいがた和牛　　国産和牛\n宮城県特産「仙台牛」　　宮城県産和牛　　国産牛\nその他、各種銘柄牛"
      }
    },
    greeting: {
      title: "代表者挨拶",
      body: `平素は暖かいご支援とご愛顧を賜り、厚く御礼申し上げます。 \n当社は、皆様に美味しく安全な牛肉をお届けして70余年。 \n新潟県を代表する「村上牛」「にいがた和牛」や宮城県を代表する「仙台牛」を中心に各種銘柄牛、国産和牛、国産牛を取り揃え、常に「安全・安心」をモットーに全社を挙げて邁進しております。\n近年では従来より取り扱っております牛肉そのものの他に、国産和牛、国産牛肉を日常的にかつより手軽にご利用いただけることを目的に、国産和牛、国産牛肉等の加工品の商品開発・製造・販売を展開しております。\n\nさらに日本産の和牛、国産牛の美味しさをより多くの方に知って頂きたく海外輸出にも注力し、食肉のグローバル化に柔軟に対応しております。\n私たちは、これからも企業理念に基づき、安全、安心、美味を追求しながら食肉を通じて豊かな食生活創りに貢献する企業を目指します。`
    },
    images: {
      title: "ギャラリー",
      description:
        "当社の本社工場及び食肉センターの設備や製造工程の一部を、写真でご紹介します。"
    },
    imageCaptinos: {
      honsya: "本社工場",
      cut: "カット計量",
      kinzoku: "金属探知機",
      syokuniku: "食肉センター",
      mooring: "係留場",
      tochiku: "とちくライン",
      unloading: "かけおろし作業",
      housou: "包装ライン"
    }
  },
  contact: {
    type: "お問い合わせの種類",
    aboutProduct: "商品等に関すること",
    aboutRecruit: "採用に関すること",
    email: "Eメールアドレス",
    body: "お問い合わせ本文",
    maxString: "2000文字以内でご入力ください",
    send: "送信",
    koudoInfo: {
      name: "よね一河渡店",
      address: "新潟県新潟市東区河渡庚248-1",
      time: "営業時間: 10:00 ~ 18:00",
      closed: "店休日: 水曜日"
    }
  }
} as const;

export const cn: Dictionary = {
  catchphrase: "米一诚心诚意的奉献。",
  navigation: {
    about: "关于我们",
    products: "经销商品",
    news: "信息",
    blog: "员工博客",
    contact: "咨询"
  },
  homepage: "主页",
  onlineshop: "网店",
  koudoShop: "河渡商店",
  descriptions: {
    mottoTop: "为顾客提供精选的安全优质牛肉。",
    mottoBottom: "美味中的一点奢侈",
    aboutSummay: `牛肉批发公司“米一”主要经销以村上牛、仙台牛的各种品牌和牛为首的日本国产牛，以及各种牛肉相关产品。\n\n`,
    onlineshopSummary: `“米一”官方网店隆重上线\n为了让更多的顾客品尝到美味的“村上牛”等“米一”优质肉，敝公司特别开设了网店。\n足不出户，就可以品尝到“米一”提供的安心安全的肉。`
  },
  about: {
    story: `安心安全是理所当然的。让顾客享受到美味中的一点奢侈。\n米一主要经销以新潟县著名品牌“村上牛”、“新潟和牛”、宫城县著名品牌“仙台牛”为首的各种品牌牛、日本国产和牛、日本国产牛等。\n同时，研制并销售以日本国产和牛、日本国产牛等为原料的加工品。 \n为了向顾客提供精美安全优质的牛肉，米一在自家工厂的所有生产工序中严格实行高标准的卫生安全管理，以确保高品质生产。\n我们希望通过为顾客提供精选的安全优质牛肉，让顾客享受到“美味中的一点奢侈”。`,
    profile: {
      title: "公司概要",
      name: {
        head: "公司名称",
        body: "米一株式会社"
      },
      ceo: {
        head: "董事长",
        body: "八木信一"
      },
      address: {
        head: "公司地址",
        body: "新潟县新潟市西区的场流通1丁目3番11号"
      },
      capital: {
        head: "资本金",
        body: "1,000万日元"
      },
      establish: {
        head: "成立",
        body: "1952年6月1日（改组为株式会社）"
      },
      directors: {
        head: "董事会成员",
        body: "董事长 八木信一\n常务董事 八木信彦\n其他董事２名"
      },
      customers: {
        head: "主要供給商",
        body:
          "全农\n畜牧业者\n东京食肉市场\n仙台中央食肉批发市场\n各地方食肉市场"
      },
      salesSection: {
        head: "主要客户",
        body:
          "各大超市\n新潟县内外食品厂家\n新潟县内外零售专店\n新潟县内外餐饮店"
      },
      offices: {
        head: "销售处・集团公司",
        body: "大阪销售处\n仙台事务所\n直销店（河渡店）\n港屋株式会社"
      },
      affilated: {
        head: "相关企业",
        body: "新潟肉联食品株式会社"
      },
      facilities: {
        head: "设施",
        body: "自家加工工厂"
      },
      products: {
        head: "经销商品",
        //eslint-disable-next-line no-irregular-whitespace
        body:
          "新潟县特产“村上牛”　　新潟和牛　　日本国产和牛\n宫城县特产“仙台牛”　　宫城县产和牛　　日本国产牛\n其他各种品牌牛"
      }
    },
    greeting: {
      title: "董事长致辞",
      body: `首先，我代表敝公司全体员工，向一贯给予我们支持和眷顾的各位表示由衷的感谢。\n敝公司七十年如一日，为顾客提供美味安全的牛肉。\n敝公司全体员工以“安全・安心”为宗旨，经销以新潟县代表品牌“村上牛”“新潟和牛”、宫城县代表品牌“仙台牛”为主的各种品牌牛、日本国产和牛、日本国产牛。\n近年来，除了多年经销的牛肉以外，敝公司开展了日本国产和牛、日本国产牛肉等加工品的商品开发、生产和销售，\n为顾客日常使用日本国产和牛、日本国产牛肉提供更多的方便。\n同时，敝公司还加强对外出口业务，让更多的顾客品尝到美味的日本产和牛、日本国产牛，为食肉全球化尽微薄之力。\n今后，敝公司仍将本着企业经营理念，通过提供安全、安心、美味的食肉，为顾客高品质的生活做贡献。`
    },
    images: {
      title: "照片展",
      description:
        "以照片的形式，介绍敝公司的自家工厂和食肉中心的部分设备及生产工序。"
    },
    imageCaptinos: {
      honsya: "总部和工厂",
      cut: "切割测量",
      kinzoku: "金属探测仪",
      syokuniku: "食肉中心",
      mooring: "停泊处",
      tochiku: "屠宰",
      unloading: "卸货",
      housou: "包装"
    }
  },
  contact: {
    type: "咨询领域",
    aboutProduct: "关于商品",
    aboutRecruit: "关于招聘",
    email: "电子邮箱",
    body: "咨询内容",
    maxString: "篇幅限于2000字以内",
    send: "发送",
    koudoInfo: {
      name: "米一河渡商店",
      address: "新潟县新潟市东区河渡庚248-1",
      time: "营业时间: 10:00 ~ 18:00",
      closed: "定休日：每周三"
    }
  }
} as const;
