import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { StyleSheet } from "aphrodite";
import { FC } from "react";
import { Button, ButtonProps } from "../Button";
import { Icon } from "../Icon";
import { fontSizes, leading, spacing, tracking } from "~/styles";

export type LinkButtonProps = Omit<ButtonProps, "styles" | "children"> & {
  children: string;
};

export const LinkButton: FC<LinkButtonProps> = ({ children, ...props }) => {
  const styles = StyleSheet.create({
    icon: {
      display: "inline-block",
      width: fontSizes.text.medium,
      height: fontSizes.text.medium,
      marginLeft: spacing.xxsmall,
      animationName: "hop-right",
      animationTimingFunction: "ease",
      animationIterationCount: "infinite",
      animationDuration: "2s"
    }
  });
  return (
    <Button
      element="a"
      styles={{
        width: "auto",
        display: "inline-flex",
        fontWeight: 700,
        lineHeight: leading.none,
        letterSpacing: tracking.wider,
        alignItems: "center",
        borderRadius: "9999px"
      }}
      {...props}
    >
      {children}
      <Icon source={faAngleDoubleRight} styles={styles.icon} />
    </Button>
  );
};
