export const fontSizes = {
  text: {
    xxsmall: "0.625em",
    xsmall: "0.75em",
    small: "0.875em",
    medium: "1em",
    large: "1.125em",
    xlarge: "1.25em",
    xxlarge: "1.375em"
  },
  title: {
    small: "1.5em",
    medium: "2.25em",
    large: "2.875em"
  }
} as const;

export const fontFamily = {
  sans: `"YakuhanJP","游ゴシック",YuGothic,YuGothicM,"Noto Sans JP",sans-serif`,
  serif: `"YakuhanMP","ShipporiMincho","Zilla Slab","游明朝","Yu Mincho",YuMincho,"Hiragino Mincho Pro",serif`,
  cnSerif: `"YakuhanMP",SimSun,"Zilla Slab","游明朝","Yu Mincho",YuMincho,"Hiragino Mincho Pro",serif`
} as const;

export const leading = {
  none: 1,
  tight: 1.25,
  sung: 1.375,
  normal: 1.5,
  relaxed: 1.625,
  loose: 2
} as const;

export const tracking = {
  tighter: "-0.05em",
  tight: "-0.025em",
  normal: "0em",
  wide: "0.025em",
  wider: "0.05em",
  widest: "0.1em"
} as const;
