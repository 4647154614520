import { css, StyleSheet } from "aphrodite";
import { ReactNode, FC } from "react";
import { fontFamily, tracking } from "~/styles";

export type HeadingProps = {
  element?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
  children?: ReactNode;
  styles?: object;
};

const styles = StyleSheet.create({
  heading: {
    fontFamily: fontFamily.serif,
    fontWeight: 700,
    letterSpacing: tracking.tight
  }
});

export const Heading: FC<HeadingProps> = ({
  element: Element = "p",
  children,
  styles: headingStyles
}) => {
  return (
    <Element className={css(styles.heading, headingStyles)}>{children}</Element>
  );
};
