import { StyleSheet } from "aphrodite";
import { VFC } from "react";
import NextImage from "next/image";
import { Layout } from "~/components/Layout";

const styles = StyleSheet.create({
  container: {
    width: "240px",
    flex: "0 0 auto"
  }
});

export const Image: VFC = () => {
  return (
    <Layout styles={styles.container}>
      <NextImage
        src="/images/logo_white.png"
        width={240}
        height={130.4}
        layout="responsive"
        loading="lazy"
      />
    </Layout>
  );
};
