import { useCallback, useState } from "react";

export const useBodyFix = () => {
  const [scrollY, setScrollY] = useState<number>(0);

  const toFix = useCallback(() => {
    const top = `-${window.scrollY}px`;
    setScrollY(window.scrollY);
    document.body.style.setProperty("position", "fixed");
    document.body.style.setProperty("overflow", "hidden");
    document.body.style.setProperty("top", top);
    document.body.style.setProperty("width", "100%");
  }, [scrollY, setScrollY]);

  const toFlow = useCallback(() => {
    document.body.style.removeProperty("position");
    document.body.style.removeProperty("overflow");
    document.body.style.removeProperty("top");
    document.body.style.removeProperty("width");
    window.scrollTo(0, scrollY);
    setScrollY(0);
  }, [scrollY]);

  return { toFix, toFlow };
};
