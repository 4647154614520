import { css, StyleSheet } from "aphrodite";
import { VFC } from "react";
import useMedia from "use-media";
import { Button as ButtonComponent } from "~/components/Button";
import { InlineText } from "~/components/InlineText";
import { Layout } from "~/components/Layout";
import { colors, fontSizes, media, shadow, spacing, zIndex } from "~/styles";

export type ButtonProps = {
  isActive: boolean;
  activateHandler: () => void;
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    color: colors.black
  },
  buttonChild: {
    display: "inline-flex",
    position: "relative",
    width: "2rem",
    height: "0.3rem",
    marginRight: "0.125rem",
    borderRadius: "0.5rem",
    backgroundColor: "currentColor",
    transition: "background 200ms ease,color 200ms ease",
    ":before": {
      top: "-0.7rem",
      width: "2.25rem",
      content: "''",
      display: "block",
      position: "absolute",
      right: "0px",
      height: "0.3rem",
      borderRadius: "0.5rem",
      backgroundColor: "currentColor",
      transitionTimingFunction: "ease",
      transitionDuration: "200ms",
      transitionProperty: "top,transform,color"
    },
    ":after": {
      top: "0.7rem",
      width: "2.5rem",
      content: "''",
      display: "block",
      position: "absolute",
      right: "0px",
      height: "0.3rem",
      borderRadius: "0.5rem",
      backgroundColor: "currentColor",
      transitionTimingFunction: "ease",
      transitionDuration: "200ms",
      transitionProperty: "top,transform,width,color"
    }
  },
  buttonChildIsActive: {
    backgroundColor: "transparent",
    ":before": {
      boxShadow: "none",
      transform: "rotate(45deg)",
      top: "0px"
    },
    ":after": {
      transform: "rotate(-45deg)",
      top: "0px",
      width: "2.25rem"
    }
  },
  buttonText: {
    display: "block",
    color: "currentColor",
    marginLeft: spacing.xxsmall
  }
});

export const Button: VFC<ButtonProps> = ({ isActive, activateHandler }) => {
  const isMedium = useMedia({
    minWidth: media.mediumWidth
  });
  return (
    <Layout element="div" aria-hidden={isMedium} styles={styles.container}>
      <ButtonComponent
        id="main-menu"
        element="button"
        type="button"
        aria-label={`menu ${!isActive ? "open" : "close"}`}
        aria-haspopup
        aria-expanded={isActive}
        onClick={activateHandler}
        styles={{
          paddingTop: spacing.xsmall,
          paddingBottom: spacing.xsmall,
          paddingRight: spacing.xsmall,
          paddingLeft: spacing.small,
          display: "inline-flex",
          alignItems: "center",
          fontSize: fontSizes.text.large,
          fontWeight: 700,
          lineHeight: 1,
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: colors.stone,
          color: "currentColor",
          transitionProperty: "color, transform, background",
          zIndex: zIndex.onPage,
          background: colors.stoneTint,
          borderRadius: "9999px",
          boxShadow: shadow.xlarge,
          width: "auto",
          ":hover": {
            background: colors.stone
          }
        }}
      >
        <span
          className={css(
            styles.buttonChild,
            isActive && styles.buttonChildIsActive
          )}
        />
        <InlineText styles={styles.buttonText}>MENU</InlineText>
      </ButtonComponent>
    </Layout>
  );
};
