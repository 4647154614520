import { css, StyleSheet } from "aphrodite";
import NextLink from "next/link";
import {
  AriaAttributes,
  FocusEventHandler,
  MouseEventHandler,
  ReactNode,
  FC
} from "react";
import { fontSizes, variables } from "~/styles";

export type LinkProps = {
  id?: string;
  href: string;
  prefetch?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  onFocus?: FocusEventHandler<HTMLAnchorElement>;
  onBlur?: FocusEventHandler<HTMLAnchorElement>;
  children?: ReactNode;
  external?: boolean;
  label?: string;
  tabIndex?: number;
  styles?: object;
};

const styles = StyleSheet.create({
  link: {
    position: "relative",
    display: "block",
    marginTop: "0",
    marginLeft: "0",
    marginRight: "0",
    marginBottom: "0",
    paddingTop: "0",
    paddingLeft: "0",
    paddingRight: "0",
    paddingBottom: "0",
    fontSize: fontSizes.text.medium,
    verticalAlign: "baseline",
    background: "transparent",
    textDecoration: "none",
    "-webkit-tap-highlight-color": "transparent",
    outline: "none",
    ":visited": {
      color: "inherit"
    },
    ":focus": {
      outline: "none"
    },
    ":focus-visible": {
      boxShadow: variables.focusRing
    }
  }
});

export const Link: FC<LinkProps & AriaAttributes> = ({
  id,
  href,
  prefetch,
  onClick,
  onFocus,
  onBlur,
  children,
  external,
  label,
  tabIndex,
  styles: linkStyles,
  ...props
}) => {
  const target = external ? "_blank" : undefined;
  const rel = external ? "noreferrer noopener" : undefined;
  return (
    <NextLink href={href} prefetch={prefetch}>
      <a
        id={id}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        role="link"
        target={target}
        rel={rel}
        aria-label={label}
        tabIndex={tabIndex}
        className={css(styles.link, linkStyles)}
        {...props}
      >
        {children}
      </a>
    </NextLink>
  );
};
