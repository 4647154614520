import { faHome, faStore } from "@fortawesome/free-solid-svg-icons";
import { StyleSheet } from "aphrodite";
import { VFC } from "react";
import { Icon } from "~/components/Icon";
import { Layout } from "~/components/Layout";
import { Link } from "~/components/Link";
import { NavigationList } from "~/components/NavigationList";
import { Text } from "~/components/Text";
import { useNavigation } from "~/hooks/useNavigation";
import {
  colors,
  fontFamily,
  fontSizes,
  leading,
  media,
  spacing
} from "~/styles";

export type WideMenuProps = {
  isMedium?: boolean;
};

export const WideMenu: VFC<WideMenuProps> = ({ isMedium }) => {
  const { navigation, home, onlineShop, onWhite } = useNavigation();

  const styles = StyleSheet.create({
    container: {
      position: "fixed",
      top: "50%",
      left: spacing.medium,
      transform: "translateY(-50%)",
      zIndex: 1,
      display: "none",
      color: onWhite ? colors.black : colors.white,
      maxHeight: "100vh",
      overflow: "auto",
      [media.medium]: {
        display: "block"
      }
    },
    nav: {
      display: "block",
      color: "inherit"
    },
    list: {
      margin: "0"
    },
    item: {},
    link: {
      display: "block",
      textDecoration: "none",
      color: "inherit",
      transition: "color 200ms ease,transform 200ms ease",
      ":hover": {
        textDecoration: "underline"
      },
      ":active": {
        transform: "scale(0.96)"
      }
    },
    linkName: {
      display: "block",
      fontFamily: fontFamily.serif,
      fontWeight: 700,
      fontSize: fontSizes.title.medium,
      margin: "0"
    },
    linkLabel: {
      display: "block",
      fontFamily: fontFamily.sans,
      fontWeight: 400,
      fontSize: fontSizes.text.small,
      margin: "0"
    },
    footer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      borderTopWidth: "1px",
      borderTopStyle: "solid",
      borderTopColor: "currentColor",
      marginTop: spacing.large,
      paddingTop: spacing.small,
      paddingRight: spacing.small,
      transition: "color 200ms ease",
      color: "inherit"
    },
    footerLink: {
      display: "inline-flex",
      alignItems: "center",
      lineHeight: leading.none,
      fontSize: fontSizes.text.small,
      fontWeight: 700,
      color: "currentColor",
      textDecoration: "none",
      paddingTop: spacing.xxsmall,
      paddingBottom: spacing.xxsmall
    },
    footerLinkIcon: {
      display: "block",
      width: "1rem",
      height: "1rem",
      marginRight: spacing.xxsmall
    }
  });
  return (
    <Layout element="div" aria-hidden={!isMedium} styles={styles.container}>
      <Layout element="nav" styles={styles.nav}>
        <NavigationList styles={styles.list}>
          {navigation.map((item, i) => (
            <NavigationList.Item
              key={`${item.name}[${i}]`}
              styles={styles.item}
            >
              <Link href={item.path} label={item.label} styles={styles.link}>
                <Text element="span" styles={styles.linkName}>
                  {item.name}
                </Text>
                <Text element="span" styles={styles.linkLabel}>
                  {item.label}
                </Text>
              </Link>
            </NavigationList.Item>
          ))}
        </NavigationList>
        <Layout element="footer" styles={styles.footer}>
          <Link href={home.path} label={home.label} styles={styles.footerLink}>
            <Icon source={faHome} styles={styles.footerLinkIcon} />
            {home.label}
          </Link>
          <Link
            href={onlineShop.path}
            label={onlineShop.label}
            styles={styles.footerLink}
            external
          >
            <Icon source={faStore} styles={styles.footerLinkIcon} />
            {onlineShop.label}
          </Link>
        </Layout>
      </Layout>
    </Layout>
  );
};
