import { css, CSSProperties, StyleSheet } from "aphrodite";
import {
  AriaAttributes,
  FocusEventHandler,
  MouseEventHandler,
  ReactNode,
  FC
} from "react";
import { Link } from "../Link";
import { colors, spacing, variables } from "~/styles";

export type ButtonProps = {
  id?: string;
  type?: "button" | "submit";
  tabIndex?: number;
  element?: "a" | "button";
  href?: string;
  external?: boolean;
  prefetch?: boolean;
  disabled?: boolean;
  chidren?: ReactNode;
  styles?: CSSProperties;
  onClick?: MouseEventHandler;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
};

export const Button: FC<ButtonProps & AriaAttributes> = ({
  id,
  element = "button",
  type = "button",
  tabIndex,
  href = "",
  external,
  prefetch,
  children,
  styles: buttonStyles = {},
  onClick,
  onFocus,
  onBlur,
  ...props
}) => {
  const styles = StyleSheet.create({
    button: {
      position: "relative",
      display: "inline-flex",
      marginTop: "0",
      marginLeft: "0",
      marginRight: "0",
      marginBottom: "0",
      paddingTop: spacing.xsmall,
      paddingBottom: spacing.xsmall,
      paddingLeft: spacing.small,
      paddingRight: spacing.small,
      borderWidth: "1px",
      borderStyle: "solid",
      color: colors.black,
      borderColor: colors.black,
      background: "transparent",
      transitionDuration: "200ms",
      transitionTimingFunction: "ease",
      transitionProperty: "background, transform, box-shadow",
      textDecoration: "none",
      textAlign: "center",
      appearance: "none",
      userSelect: "none",
      outline: "none",
      cursor: "pointer",
      touchAction: "manipulation",
      "-webkit-tap-highlight-color": "transparent",
      ":hover": {
        color: colors.black,
        backgroundColor: colors.shadow
      },
      ":focus": {
        outline: "none"
      },
      ":active": {
        transform: "scale(0.96)"
      },
      ":focus-visible": {
        boxShadow: variables.focusRing
      },
      ...buttonStyles
    }
  });
  const Element =
    element === "button" ? (
      <button
        id={id}
        type={type}
        tabIndex={tabIndex}
        className={css(styles.button)}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        {...props}
      >
        {children}
      </button>
    ) : (
      <Link
        id={id}
        href={href}
        external={external}
        prefetch={prefetch}
        tabIndex={tabIndex}
        styles={styles.button}
        onClick={onClick}
        onBlur={onBlur}
        onFocus={onFocus}
        {...props}
      >
        {children}
      </Link>
    );
  return Element;
};
