import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css, StyleSheet } from "aphrodite";
import { VFC } from "react";
import { fontSizes } from "~/styles";

export type IconProps = {
  source: IconDefinition;
  styles?: object;
};

const styles = StyleSheet.create({
  container: {
    display: "block",
    width: fontSizes.text.medium,
    height: fontSizes.text.medium,
    pointerEvents: "none",
    position: "relative",
    minWidth: "0px",
    minHeight: "0px",
    maxWidth: "100%",
    maxHeight: "100%",
    userSelect: "none"
  },
  icon: {
    display: "block",
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: "100%"
  }
});

export const Icon: VFC<IconProps> = ({ source, styles: iconStyles }) => {
  return (
    <span className={css(styles.container, iconStyles)}>
      <FontAwesomeIcon icon={source} className={css(styles.icon)} />
    </span>
  );
};
